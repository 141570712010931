import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import supportService from '@/services/support.service'
import EventBus from '@/util/EventBus'

export default {
  name: 'Suport',
  components: { HeaderTopDashboard },
  data: function () {
    return {
      messageResult: 0,
      supportMessage: {
        subject: null,
        message: null,
      },
    }
  },
  computed: {
    sedes: function () {
      return [
        {
          sede: this.$t('Sede Central'),
          img: 'img/flags/es.svg',
          email: 'info@360nrs.com',
          phone: '+34 964 523 331',
          hours: this.$t('de Lunes a Jueves : de 9:00h a 14:00h y de 15:30h a 19:00h Viernes: de 8:00h a 15:00h'),
        },
        {
          sede: this.$t('España'),
          img: 'img/flags/es.svg',
          email: 'madrid@360nrs.com',
          phone: '+34 910 516 255',
          hours: this.$t('de Lunes a Jueves : de 9:00h a 14:00h y de 15:30h a 19:00h Viernes: de 8:00h a 15:00h'),
        },
        {
          sede: this.$t('Italia'),
          img: 'img/flags/it.svg',
          email: 'info@360nrs.com',
          phone: '+39 02 8704 9960',
          hours: this.$t('de Lunes a Jueves : de 9:00h a 14:00h y de 15:30h a 19:00h Viernes: de 8:00h a 15:00h'),
        },
        {
          sede: this.$t('Colombia'),
          img: 'img/flags/co.svg',
          email: 'support-co@360nrs.com',
          phone: '+57 60 4 3201515',
          hours: this.$t('de Lunes a Viernes: de 8:00h a 17:00h'),
        },
        {
          sede: this.$t('México'),
          img: 'img/flags/mx.svg',
          email: 'mexico@360nrs.com',
          phone: '+52 55 4162 9152',
          hours: this.$t('de Lunes a Viernes: de 8:00h a 17:00h'),
        },
        {
          sede: this.$t('Rumanía'),
          img: 'img/flags/ro.svg',
          email: 'info@360nrs.com',
          phone: '+40 31 631 3144',
          hours: this.$t('de Lunes a Jueves : de 9:00h a 13:00h y de 14:30h a 19:00h Viernes: de 8:00h a 15:00h'),
        },
      ]
    },
  },
  methods: {
    sendForm () {
      const data = {
        subject: this.supportMessage.subject,
        message: this.supportMessage.message,
      }
      supportService.sendSupportMessage(data)
      .then(
        (response) => {
          this.messageResult = response.sent
          EventBus.$emit('showAlert', 'success', this.$t('Se ha enviado correctamente el mensaje.'))
        },
        () => {
          this.messageResult = 0
          EventBus.$emit('showAlert', 'danger', this.$t('Se ha producido un error al enviar el mensaje.'))
        },
      )
      .finally(_ => {
        this.showFormInputs = false
        this.clearForm()
      })
    },
    clearForm () {
      this.supportMessage = {
        subject: null,
        message: null,
      }
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
  },
}
